import React, { useEffect } from "react";
import { RiGraduationCapFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { fetchAcademyLists } from "../redux/actions/academy";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
import { BsFiletypeDoc } from "react-icons/bs";
import { GrDocumentCsv } from "react-icons/gr";
import Loading from "../components/Loading";
import { usePostHog } from 'posthog-js/react'
const Academy = () => {
  const posthog = usePostHog()

  const dispatch = useDispatch();
  const { videos, pdfs, docs, csvs, loading } = useSelector((state) => state.academy);

  useEffect(() => {
    dispatch(fetchAcademyLists());
  }, []);

  const handleMediaClick = (mediaType, media) => {
    posthog.capture("Academy Source Viewed", {
      media_type: mediaType,
      title: media.title,
    });
  };

  return (
    <div className="px-8 py-6 min-w-full overflow-x-hidden flex flex-col gap-y-6">
      <div className="flex flex-col">
        <div className="title flex gap-x-3">
          <GiTakeMyMoney color="green" className="w-9 h-9" />
          ProfitBoost Academy
        </div>
        <p className="subtitle">
          Unlock the secrets of successful Amazon reselling with Profit Boost Academy's expert training and
          comprehensive resource platform..
        </p>
      </div>
      <div className="flex flex-col w-full border rounded-lg"></div>
      {!videos && !pdfs && !csvs && !docs && (
        <div className="w-full my-5 flex justify-center text-gray-500">Nothing to show.</div>
      )}

      {loading ? (
        <div className="w-full flex justify-center my-5">
          <Loading />
        </div>
      ) : (
        <>
          <div className="w-full  md:px-8 flex gap-x-5 py-2 justify-center sm:justify-start   gap-y-3 flex-wrap">
            {videos?.map((video) => {
              return (
                <Link
                  to={video.videoLink}
                  target="_blank"
                  onClick={() => handleMediaClick("video", video)}
                  className=" w-[80%] sm:w-[40%]  md:w-[30%]  lg:w-[25%] flex flex-col gap-y-1 p-3 rounded-xl border border-gray-300 hover:bg-gray-100"
                >
                  <img
                    src={video.imageUrl}
                    alt={video.title}
                    className=" object-contain"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <span className="text-xs md:text-base font-semibold">{video.title}</span>
                  <span className="text-sm font-light mt-2">{video.type}</span>
                  <p className="tracking-tight text-xs font-light ">{video.description}</p>
                </Link>
              );
            })}
          </div>
          <div className="w-full  px-8 flex gap-x-5 gap-y-3 py-2 flex-wrap">
            {pdfs?.map((pdf) => {
              return (
                <Link
                  to={pdf.fileLink}
                  target="_blank"
                  onClick={() => handleMediaClick("pdf", pdf)}
                  className=" sm:w-[60%] md:w-[40%] lg:w-[30%] flex items-center  gap-y-1  py-2 px-4 rounded-xl border border-gray-300 hover:bg-gray-100"
                >
                  <div className="w-[20%]">
                    <FaFilePdf className="w-8 mx-2 h-8 " color="maroon" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <span className="text-sm font-semibold">{pdf.title}</span>
                    {/* <span className='text-sm font-light mt-2'>{pdf.type}</span> */}
                    <p className="tracking-tighter text-xs font-light mt-1">{pdf.description}</p>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="w-full px-8 flex gap-x-5 gap-y-3 py-2 flex-wrap">
            {docs?.map((doc) => {
              return (
                <Link
                  to={doc.fileLink}
                  target="_blank"
                  onClick={() => handleMediaClick("doc", doc)}
                  className="flex items-center w-[30%] gap-y-1  py-2 px-4 rounded-xl border border-gray-300 hover:bg-gray-100"
                >
                  <div className="w-[20%]">
                    <BsFiletypeDoc className="w-8 mx-2 h-8 " color="navy" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <span className="text-sm font-semibold">{doc.title}</span>
                    {/* <span className='text-sm font-light mt-2'>{pdf.type}</span> */}
                    <p className="tracking-tighter text-xs font-light mt-1">{doc.description}</p>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="w-full px-8 flex gap-x-5 gap-y-3 py-2 flex-wrap">
            {csvs?.map((csv) => {
              return (
                <Link
                  to={csv.fileLink}
                  target="_blank"
                  className="flex items-center w-[30%] gap-y-1  py-2 px-4 rounded-xl border border-gray-300 hover:bg-gray-100"
                >
                  <div className="w-[20%]">
                    <GrDocumentCsv className="w-8 mx-2 h-8 " color="navy" />
                  </div>
                  <div className="flex flex-col justify-center">
                    <span className="text-sm font-semibold">{csv.title}</span>
                    {/* <span className='text-sm font-light mt-2'>{pdf.type}</span> */}
                    <p className="tracking-tighter text-xs font-light mt-1">{csv.description}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Academy;

import { useEffect, useCallback } from "react";
import ConfirmationModal from "./ConfirmationModal";
import Loading from "./Loading";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { redirectCustomerPortal } from "../redux/actions/stripe";
import { redirectCustomerPortal } from "../redux/actions/stripe";
import { toast } from "sonner";
const PaymentStatus = () => {
  const { user, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const navigateToSubscribe = useCallback(() => {
  //   navigate("/subscribe");
  // }, [navigate]);

  console.log(user, "user in payment status");
  // useEffect(() => {
  //   if (user && (!user.subscription || user.subscription === "canceled")) {
  //     navigateToSubscribe();
  //   }
  // }, [user, navigateToSubscribe]);

  const isPaymentPaid = user && user.paymentStatus === "paid";
  const isPaymentPending = user && user.paymentStatus === "pending";
  const isPaymentCanceled = user && user.paymentStatus === "canceled";
  const isSubscriptionActive = new Date(user?.subscriptionExpiresAt) > new Date();

  


  console.log(isPaymentPaid || isPaymentPending || (isPaymentCanceled && isSubscriptionActive));

  return user === null ? (
    <div className="w-screen h-screen flex justify-center items-center">
      <Loading />
    </div>
  ) : isPaymentPaid || isPaymentPending || (isPaymentCanceled && isSubscriptionActive) ? (
    <Outlet />
  ) : (
    <ConfirmationModal
      heading="Payment Pending"
      message="Please complete your payment via the billing portal to access the platform."
      //   onCancel={() => navigate("/")}
      onConfirm={() => {
        try {
          // Add a loading toast
          // toast.promise(
          //   dispatch(redirectCustomerPortal(user.stripeCustomerId)),
          //   {
          //     loading: "Redirecting to the payment portal...",
          //     success: "Redirected successfully",
          //     error: "Failed to redirect",
          //   }
          // )
          navigate("/settings");
          // dispatch(redirectCustomerPortal(user.stripeCustomerId));
         } catch (error) {
          console.log(error);
         }
      }}
      btnText="Manage Payment 🚀"
    />
  );
};

export default PaymentStatus;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getUserDetails, handleSignOut } from '../redux/actions/auth';
import { createSubscription, upgradeSubscription } from './Subscription/CreateSubscription';
import { toast } from "sonner";
import { motion } from 'framer-motion';
import { Check, Sparkles } from 'lucide-react';

const SignupPricingTable = ({ planSelected, setPlanSelected }) => {
    const [loading, setLoading] = useState(null);
    const [isMonthly, setIsMontly] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const newPlans = [
        {
            id: 1,
            name: "Monthly",
            price: "50",
            features: [
                "6 winning products a day",
                "Minimum $2 profit per unit",
                "Inventory Tracking Ssytem",
                "Build in profit & Sales calculator",
            ],
            plan: "pro",
            isMonthly: true,
            stripePlanId: process.env.REACT_APP_REVISED_MONTLY_PRICE_ID,
        },
        {
            id: 2,
            name: "Yearly",
            price: "480",
            features: [
                "6 winning products a day",
                "Minimum $2 profit per unit",
                "Inventory Tracking Ssytem",
                "Build in profit & Sales calculator",
                "Community Access",
            ],
            plan: "pro",
            isMonthly: false,
            stripePlanId: process.env.REACT_APP_REVISED_YEARLY_PRICE_ID,
        },
    ];

    useEffect(() => {
        if (planSelected && planSelected?.isMonthly !== true) {
            setIsMontly(true);
        }
    }, []);

    return (
        <div className="py-8 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
            <div className="text-center space-y-4 mb-12">
                <h2 className="text-3xl font-bold text-slate-900">Choose Your Plan</h2>
                <p className="text-lg text-slate-600">Get started with the plan that fits your needs</p>
            </div>

            <div className="flex flex-wrap justify-center gap-8 max-w-3xl  mx-auto">
                {newPlans.map((item, index) => (
                    <motion.div
                        key={item.stripePlanId}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                    >
                        <div
                            className={`h-full w-full max-w-lg rounded-2xl border transition-all duration-300 hover:shadow-lg hover:scale-105 relative overflow-hidden flex flex-col ${item.isMonthly ? 'border-slate-200 bg-white/80' : 'border-primary/50 bg-purple-50/50'
                                }`}
                        >
                            {!item.isMonthly && (
                                <div className="absolute -right-12 top-6 rotate-45 bg-primary px-12 py-1 text-sm font-medium text-white">
                                    30% off
                                </div>
                            )}

                            <div className="p-8 flex flex-col flex-1">
                                {/* Header */}
                                <div className="text-center mb-8">
                                    <h3 className="text-xl font-bold text-slate-900 mb-2">
                                        {item.name} Plan
                                    </h3>
                                    <div className="flex items-baseline justify-center gap-x-2">
                                        <span className="text-4xl font-bold text-slate-900">${item.price}</span>
                                        <span className="text-slate-600">/{item.isMonthly ? 'mo' : 'yr'}</span>
                                    </div>
                                </div>

                                {/* Features */}
                                <div className="space-y-4 mb-8 flex-1">
                                    {item.features.map((feature, idx) => (
                                        <div key={idx} className="flex items-center gap-3">
                                            <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-primary/10">
                                                <Check className="h-4 w-4 text-primary" />
                                            </div>
                                            <span className={`text-sm font-medium ${idx > 3 ? 'text-purple-600' : 'text-slate-600'}`}>
                                                {feature}
                                            </span>
                                        </div>
                                    ))}
                                </div>

                                {/* Action Button */}
                                {planSelected.stripePlanId === item.stripePlanId ? (
                                    <button
                                        disabled
                                        className="w-full rounded-lg bg-primary/10 text-primary py-3 px-4 text-sm font-semibold"
                                    >
                                        <div className="flex items-center justify-center gap-2">
                                            <Check className="h-4 w-4" />
                                            Selected Plan
                                        </div>
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => setPlanSelected(item)}
                                        disabled={loading}
                                        className="w-full rounded-lg bg-primary hover:bg-primary/90 text-white py-3 px-4 text-sm font-semibold transition-all duration-200 hover:scale-[1.02]"
                                    >
                                        {loading ? (
                                            <div className="flex items-center justify-center gap-2">
                                                <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                        fill="none"
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                                Processing...
                                            </div>
                                        ) : (
                                            'Choose Plan'
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default SignupPricingTable;
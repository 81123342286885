import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { handleSignIn, handleSignUp } from "../../redux/actions/auth";
import SignupPricingTable from "../../components/SignupPricingTable";
import { useDispatch } from "react-redux";
import {
  CardElement,
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";
import { toast } from "sonner";
import { usePostHog } from 'posthog-js/react';
import { Check, Rocket } from 'lucide-react';

function Authentication() {
  // Existing state and hooks remain the same
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname.slice(1);

  // All other existing state variables and functions remain unchanged
  const [rememberState, setRememberState] = useState(false);
  const [planSelected, setPlanSelected] = useState({
    id: 4,
    name: "Monthly",
    price: "50",
    features: [
      "6 winning products a day",
      "Minimum $2 profit per unit",
      "Inventory Tracking Ssytem",
      "Build in profit & Sales calculator",
  ],
    plan: "pro",
    isMonthly: true,
    stripePlanId: process.env.REACT_APP_REVISED_MONTLY_PRICE_ID,
  });

  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    name: "",
    email: "",
    password: "",
  });

  console.log(loginData, "loginData");
  console.log(planSelected, "planSelected");

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("setupModalStatus");
  }, []);

  // handle Input
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };

  // handle submit
  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (currentRoute === "signup") {
        console.log('checking if stripe and planSelected exists', stripe, elements, planSelected)

        if (!stripe || !elements || !planSelected) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          console.log("stripe not loaded")

          toast.error("Something went wrong!");
          return;
        }

        console.log('creating payment method')

        const cardElement = elements.getElement(CardElement);

        console.log(cardElement, "cardElement")

        if (!cardElement) {
          console.error('Card element not found');
          return;
        }

        // Create a payment method using the card Element
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (result?.error) {
          // Handle errors
          console.error(result.error.message);
          toast.error(result.error.message);
          setMessage(result.error.message);
          setLoading(false);
          return;
        }
        posthog.capture('Signup Button Clicked', { planSelected: planSelected.name });

        let res = await handleSignUp({ ...loginData, paymentMethodId: result.paymentMethod.id, planSelected: planSelected });
        // if (res) {
        //   await signIn(loginData);
        //   navigate("/signin");
        // }
        console.log(result.paymentMethod.id, "result.paymentMethod.id")

        const clientSecret  = res?.clientSecret;

        if(!clientSecret) {
          console.error('Client secret not found');
          toast.error(res?.message || 'Something went wrong! Please try again');
          return;
        }

        const { paymentMethod, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: result.paymentMethod.id
        });

        console.log(error)

        await signIn(loginData);
        navigate("/signin");
        return
        if (error) {
          console.error(error.message);
          toast.error(error.message);
          return;
        } else {
          console.log(paymentMethod, "paymentMethod")
          // The payment has been processed!
          if (paymentMethod) {
            console.log('Payment successful');
            toast.success('Payment successful');
            await signIn(loginData);
            navigate("/signin");
          }
        }
      } else {
        let res = await handleSignIn(loginData);
        console.log(res, "res");
        if (res?.success) {
          dispatch({ type: "SET_USER", payload: res.data });
          navigate("/lists");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (loginData) => {
    let res = await handleSignIn(loginData);
    console.log(res, "res");
    if (res) {
      dispatch({
        type: "SET_USER",
        payload: res.data,
      });

      navigate("/lists");
    } else {
      dispatch({
        type: "CHANGE_ALERT_STATE",
        alerttype: "failed",
        heading: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  useEffect(() => {
    if (currentRoute === 'signup') {
      console.log('signup page viewed')
      posthog.capture('Signup Page Viewed');
    } 
  },[currentRoute])
  const defaultCardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#94a3b8',
        },
        backgroundColor: 'white',
      },
      invalid: {
        color: '#ef4444',
      },
    },
    hidePostalCode: true,
  };

  return (
    <div className={`min-h-screen bg-slate-50 ${currentRoute === 'signup' ? 'flex flex-col lg:flex-row items-center lg:items-start lg:justify-between' : 'grid place-items-center'}`}>
      <div className={`${currentRoute === "signup" ? 'lg:w-[40%] pt-8 lg:pt-16 px-6 lg:px-16' : 'w-full max-w-md px-6'}`}>
        <div className="max-w-md mx-auto">
          {/* Logo Section */}
          <div className="flex items-center gap-x-2 mb-8">
            <div className="p-2 bg-primary/10 rounded-xl">
              <Rocket className="w-6 h-6 text-primary" />
            </div>
            <h1 className="text-2xl font-bold text-slate-900">
              Profitboost
            </h1>
          </div>

          {/* Header Section */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-slate-900 mb-2">
              {currentRoute === "signup" ? "Create your account" : "Welcome back"}
            </h2>
            <p className="text-slate-600">
              {currentRoute === "signup"
                ? "Get started with your free account today"
                : "Sign in to continue to your account"}
            </p>
          </div>

          {/* Form Section */}
          <form onSubmit={handleSubmit} className="space-y-5">
            {currentRoute === "signup" && (
              <div>
                <label className="block text-sm font-medium text-slate-700 mb-1.5">
                  Full Name
                </label>
                <input
                  className="w-full px-4 py-2.5 rounded-lg border border-slate-300 focus:ring-2 focus:ring-primary/20 focus:border-primary transition-all duration-200 bg-white"
                  type="text"
                  name="name"
                  onChange={handleInputs}
                  placeholder="John Doe"
                  required
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1.5">
                Email Address
              </label>
              <input
                className="w-full px-4 py-2.5 rounded-lg border border-slate-300 focus:ring-2 focus:ring-primary/20 focus:border-primary transition-all duration-200 bg-white"
                type="email"
                name="email"
                onChange={handleInputs}
                placeholder="you@example.com"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1.5">
                Password
              </label>
              <input
                className="w-full px-4 py-2.5 rounded-lg border border-slate-300 focus:ring-2 focus:ring-primary/20 focus:border-primary transition-all duration-200 bg-white"
                type="password"
                name="password"
                onChange={handleInputs}
                placeholder={currentRoute === "signup" ? "Create a password (min. 8 characters)" : "Enter your password"}
                minLength={8}
                required
              />
            </div>

            {currentRoute === "signup" && (
              <div className="space-y-4">
                <label className="block text-sm font-medium text-slate-700 mb-1.5">
                  Card Details
                </label>
                <div className="p-4 rounded-lg border border-slate-300 bg-white">
                  <CardElement options={defaultCardElementOptions} />
                </div>
                
                <div className="flex items-start gap-x-3 mt-4">
                  <input
                    type="checkbox"
                    className="mt-1 h-4 w-4 rounded border-slate-300 text-primary focus:ring-primary"
                    required
                  />
                  <p className="text-sm text-slate-600">
                    I agree to the{" "}
                    <a href="#" className="text-primary hover:underline">
                      Terms & Conditions
                    </a>
                  </p>
                </div>
              </div>
            )}

            {currentRoute === "signin" && (
              <div className="flex justify-end">
                <button
                  onClick={() => navigate("/forgot-password")}
                  type="button"
                  className="text-sm text-primary hover:underline"
                >
                  Forgot password?
                </button>
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2.5 px-4 bg-primary hover:bg-primary/90 text-white rounded-lg font-medium transition-colors duration-200 flex items-center justify-center gap-2"
            >
              {loading ? (
                <>
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>Processing...</span>
                </>
              ) : (
                <span>{currentRoute === "signup" ? "Create Account" : "Sign In"}</span>
              )}
            </button>
          </form>

          <p className="mt-6 text-center text-sm text-slate-600">
            {currentRoute === "signup" ? (
              <>
                Already have an account?{" "}
                <Link to="/signin" className="text-primary hover:underline font-medium">
                  Sign in
                </Link>
              </>
            ) : (
              <>
                Don't have an account?{" "}
                <Link to="/signup" className="text-primary hover:underline font-medium">
                  Sign up
                </Link>
              </>
            )}
          </p>
        </div>
      </div>

      {currentRoute === "signup" && (
        <div className="w-full lg:w-[60%] p-6 lg:p-16">
          <SignupPricingTable setPlanSelected={setPlanSelected} planSelected={planSelected} />
        </div>
      )}
    </div>
  );
}

export default Authentication;
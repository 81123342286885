import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

const StatusLabel = ({ active, onUpgrade, modal }) => {
  if (active) {
    return (
      // <div className="flex flex-col items-center gap-2">
      //   <div className="inline-flex items-center gap-2 rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-600">
      //     <span className="h-2 w-2 animate-pulse rounded-full bg-green-500"></span>
      //     Active
      //   </div>
      // </div>
       <button
       disabled={modal}
       className="inline-flex w-full items-center justify-center rounded-lg bg-white px-4 py-2.5 text-sm font-semibold text-primary border-primary border-[1.5px] shadow-sm transition-all hover:bg-primary hover:text-white hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-70"
     >
       Active Plan
     </button>
    );
  }

  return (
    <button
      disabled={modal}
      onClick={onUpgrade}
      className="inline-flex w-full items-center justify-center rounded-lg bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-70"
    >
      {modal ? (
        <span className="flex items-center gap-2">
          <svg className="h-4 w-4 animate-spin" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          Processing...
        </span>
      ) : (
        'Upgrade Plan'
      )}
    </button>
  );
};

export const StatusCard = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const isAnnual = props?.planType === 'Annual';


  return (
    <div className={`relative overflow-hidden rounded-xl border transition-all duration-300 hover:shadow-lg hover:scale-105 
      ${isAnnual ? 'border-primary/50 bg-purple-50/50' : 'border-gray-200'}
      ${props.currentPlanId === props.planId && 'hidden'}
      `}>
      {/* Best Value Banner */}
      {isAnnual && (
        <div className="absolute -right-12 top-6 rotate-45 bg-primary px-12 py-1 text-sm font-medium text-white">
          Best Value
        </div>
      )}

      {/* Header */}
      <div className="px-6 pt-6">
        <div className="text-center">
          <h3 className="text-xl font-bold tracking-tight text-slate-700">
            {props?.planType === "Annual" ? "Annual Plan" : "Monthly Plan"}
          </h3>
          {props?.planAmount && (
            <div className="mt-2 flex items-center justify-center gap-1">
              <span className="text-3xl font-bold text-slate-800">${props.planAmount}</span>
              <span className="text-sm text-gray-500">
                /{props?.planType === "Annual" ? "year" : "month"}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Features */}
      <div className="mt-6 space-y-4 px-6">
        {props?.features?.map((feature, idx) => (
          <div key={idx} className="flex items-center gap-3">
            <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-purple-100">
              <svg
                className="h-4 w-4 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <span className={`text-sm font-medium ${idx > 3 ? 'text-purple-600' : 'text-slate-600'} `}>{feature}</span>
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className="mt-6 p-6">
        <StatusLabel
          active={props.currentPlanId === props.planId}
          modal={modal}
          onUpgrade={() => {
            props.onUpgrade(props);
          }}
        />
      </div>

     

    </div>
  );
};

export default StatusCard;
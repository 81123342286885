import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const handleSignIn = async ({ email, password }) => {
  try {
    const { data, status } = await instance.post("/user/signin", {
      email: email,
      password: password,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Logged in successfully!");
      return { success: true, data: data };
    }
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  }
};

export const handleSignUp = async ({ email, password, name, paymentMethodId, planSelected }) => {
  try {
    const { data, status } = await instance.post("/user/signup", {
      email: email,
      password: password,
      name: name,
      paymentMethodId: paymentMethodId,
      planSelected: planSelected,
    });
    if (status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      toast.success("Signed up successfully!");
      return data;
    }
  } catch (error) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
  }
};

export const getUserDetails = () => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const { data, status } = await authenticatedInstance.get("/user/getUserDetails", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (status === 200) {
        dispatch({
          type: "SET_USER",
          payload: data,
        });
      }
    } catch (error) {
      console.log(error, "Error in getting user details");
      toast.error("Error in getting user details");
    } finally {
    }
  };
};

export const handleSignOut = () => {
  return async (dispatch) => {
    try {
      // const { status } = await authenticatedInstance.post("/user/signout");

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      dispatch({
        type: "SET_USER",
        payload: null,
      });
      toast.success("Signed out successfully!");
      // navigate(`/signin`)
    } catch (error) {
      console.log(error, "Error in signout");
      toast.error("Something went wrong!");
    }
  };
};

export const handleFreeAccess = async ({ name, email }) => {
  try {
    const { data, status } = await instance.post("/user/free-access", {
      name: name,
      email: email,
    });
    if (status === 200) {
      toast.success("Free access assigned successfully!");
      return data;
    }
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  }
}
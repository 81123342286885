import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";

export async function upgradeSubscription(userId, userEmail, tierId, planId, customerId, email, isMonthly) {
  try {
    const { data } = await authenticatedInstance.post("/list/upgradeSubscription", {
      isMonthly,
      priceId: planId,
      userId: userId,
      tierId: tierId,
      customerId: customerId,
      email: userEmail,
    });

    return data;
  } catch (error) {
    throw email;
  }
}
export async function createSubscription(userId, userEmail,  planId,  isMonthly) {
  try {
    const { data } = await authenticatedInstance.post("/list/createSubscription", {
      isMonthly,
      planId: planId,
      userId: userId,
      email: userEmail,
    });

    console.log(data, "check");
    return data;
  } catch (error) {
    throw userEmail;
  }
}

export async function unsubscribe(listId, stripePlanId, userId) {
  console.log(listId, stripePlanId, userId);
  try {
    const { status } = await authenticatedInstance.post("/list/unsubscribe", {
      listId: listId,
      userId: userId,
      stripePlanId: stripePlanId,
    });

    return {
      status: status,
    };
  } catch (error) {
    throw error;
  }
}
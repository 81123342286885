import React, { useState } from "react";
import { Check, Rocket } from "lucide-react";

import { handleFreeAccess } from "../../redux/actions/auth";

import { useDispatch } from "react-redux";
import { toast } from "sonner";
const FreeAccess = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    adminCode: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (formData.adminCode !== process.env.REACT_APP_ADMIN_CODE) {
        toast.error("Invalid admin code");
        console.log("Invalid admin code");
        return;
      }

      const response = await handleFreeAccess(formData);
        if (response) {
            toast.success("Email sent successfully");
            console.log("Email sent successfully");
            }
      // Add your API logic here
    } catch (error) {
      console.log(error);
    } finally {
        setLoading(false);
        setFormData({
            name: "",
            email: "",
            adminCode: "",
        });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-lg p-8">
        <div className="flex items-center justify-center gap-x-2 mb-8">
          <div className="p-2 bg-primary/10 rounded-xl">
            <Rocket className="w-6 h-6 text-primary" />
          </div>
          <h1 className="text-2xl font-bold text-slate-900">Profitboost</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
            <input
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary transition-all duration-200"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputs}
              placeholder="John Doe"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
            <input
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary transition-all duration-200"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputs}
              placeholder="you@example.com"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Admin Code</label>
            <input
              className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary transition-all duration-200"
              type="password"
              name="adminCode"
              value={formData.adminCode}
              onChange={handleInputs}
              placeholder="Enter admin code"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary focus:ring-4 focus:ring-primary/50 transition-all duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FreeAccess;

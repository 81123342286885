import { connect, useSelector } from "react-redux";
import * as React from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Skeleton } from "antd";
import DropDown from "../ProductDetails/DropDown";
import authenticatedInstance from "../../redux/actions/AxiosInstance/authenticated";

export const TreeTable = (props) => {
  var { transformedData: products, pagination } = useSelector((state) => state.product.products);
  const [tableLoader, setTableLoader] = React.useState(true);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text, record) =>
        Number.isInteger(record.key) ? (
          <>
            <span>{text}</span>
            <span className="text-sm fade-in text-secondarySupport text-Inter mx-1">{record.leads} </span>{" "}
          </>
        ) : (
          <div className="flex items-center">
            {
              record?.image && <img className="h-7 w-7 mr-2" src={record.image} alt="Description" />
            }
            <Link to={`/productDetails/${record.key}`} className="text-right truncate ">
              {text ? (text.length > 50 ? text.slice(0, 50) + "..." : text) : "No Product Name"}
            </Link>
          </div>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (text ? <DropDown productId={record.productId} status={record.status} /> : null),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Selling Price",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "Est. Sales/Mo",
      dataIndex: "estSales",
      key: "estSales",
    },
    {
      title: "Current BSR",
      dataIndex: "currentBsr",
      key: "currentBsr",
    },
    {
      title: "90 Day BSR",
      dataIndex: "90DayBsr",
      key: "90DayBsr",
    },
    {
      title: "Badges",
      dataIndex: "badges",
      key: "badges",
      render: (text, record) =>
        text ? (
          <p
            className={`text-Inter text-sm text-${record.badgeColor}-600 bg-${record.badgeColor}-100 py-1 px-2.5 w-fit rounded- flex items-start gap-x-3md`}
          >
            {text}
          </p>
        ) : null,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text) =>
        text ? (
          <a href={text} target="_blank" style={{ color: "#1e90ff" }}>
            View
          </a>
        ) : null,
    },
    {
      title: "AMZ Link",
      dataIndex: "amzLink",
      key: "amzLink",
      render: (text) =>
        text ? (
          <a href={text} target="_blank" rel="noopener noreferrer" style={{ color: "#1e90ff" }}>
            View
          </a>
        ) : null,
    },
  ];

  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);

  const fetchProducts = async (page = 1) => {
    try {
      setTableLoader(true);
      if (user) {
        const resp = await authenticatedInstance.post("/list/retrieveListProducts", {
          listIds: 11,
          userId: user?.userId,
          page: page,
          limit: 100,
        });
        dispatch({ type: "SET_PRODUCTS", payload: resp.data });
        setCurrentPage(page);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTableLoader(false);
    }
  };

  React.useEffect(() => {
    if (user) {
      fetchProducts();
    }
  }, [user]);



  React.useEffect(() => {
    async function fetchData() {
      try {
        setTableLoader(true);
        let dates = {};

        // Sort products by date
        products = products.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

        for (let i = 0; i < products.length; i++) {
          const productJson = products[i].productJson || {}; // Fallback to empty object if productJson is undefined
          const basicInfo = productJson.basicInfo || {};
          const sellerCentral = productJson.sellerCentral || {};
          const overview = productJson.overview || {};
          const BSR = overview.BSR || {};
          const ranksandprices = products[i].ranksandprices || {};
          const bsr_ranks = ranksandprices.bsr_ranks || {};
          const tags = Array.isArray(productJson.tags) ? productJson.tags : []; // Ensure tags is an array

          const roi = calculateROI(
            Number(products[i].costPrice),
            Number(products[i].salePrice),
            Number(productJson.profitCalculator?.total_fees?.total || 0)
          );

          if (!dates[new Date(products[i].publishedAt).toLocaleDateString('en-GB')]) {
            dates[new Date(products[i].publishedAt).toLocaleDateString('en-GB')] = [];
          }

          dates[new Date(products[i].publishedAt).toLocaleDateString('en-GB')].push({
            key: products[i].key,
            productId: products[i].productId,
            product: products[i].product
              ? products[i].product
              : 'No Product Name',
            status: products[i].status,
            source: products[i].source,
            amzLink: products[i].amzLink,
            category: products[i].category,
            cost: products[i].cost,
            sellingPrice: products[i].sellingPrice,
            roi: products[i].roi,
            estSales:
              products[i].estSales === -1
                ? '-'
                : products[i].estSales !== null && products[i].estSales !== undefined ? products[i].estSales?.toLocaleString() : '-',
            currentBsr:
              products[i]?.currentBsr === -1
                ? '-'
                : products[i]?.currentBsr !== null && products[i]?.currentBsr !== undefined ? products[i]?.currentBsr.toLocaleString() : '-',
            "90DayBsr":
              products[i]['90DayBsr'] === -1
                ? '-'
                : products[i]['90DayBsr'] !== null && products[i]['90DayBsr'] !== undefined ? products[i]['90DayBsr'].toLocaleString() : '-',
            badges: products[i]?.badges,
            badgeColor: products[i]?.badgeColor,
            image: products[i]?.image,
          });
        }

        setData(
          Object.keys(dates).map((date, index) => {
            return {
              key: index,
              product: date,
              leads: `(${dates[date].length} leads)`,
              children: dates[date],
            };
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        setTableLoader(false);
      }
    }
    fetchData();
  }, [products]);

  const handleTableChange = (pagination) => {
    console.log(pagination.current, 'ss')
    fetchProducts(pagination.current);
  };

  const calculateROI = (cost_price, sale_price, amazonFees) => {
    const final_sale_price = sale_price - Number(amazonFees);
    const roi = ((final_sale_price - cost_price) / cost_price) * 100;
    return roi.toFixed(2);
  };

  return <Table
    columns={columns}
    dataSource={data}
    loading={!user || !data || tableLoader}
    pagination={{
      current: currentPage,
      pageSize: 100,
      total: pagination?.totalProducts,
      showSizeChanger: false,
    }}
    onChange={handleTableChange}
    scroll={{ x: 1500 }}
  />
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TreeTable);

import React, { useEffect, useState } from "react";
import { StatusCard, Table } from "../components/Dashboard";
import { fetchListsAndProducts, refreshProductsJson } from "../redux/actions/list";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "antd";
import { Modal } from "../components/Modal";
import { BarsOutlined, SyncOutlined } from "@ant-design/icons";
import { Skeleton, Row, Col } from "antd";
import { toast } from "sonner";
import ConfirmationModal from "../components/ConfirmationModal";
import { upgradeSubscription } from "../components/Subscription/CreateSubscription";
export const Dashboard = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [limitModal, setLimitModal] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const data = useSelector((state) =>
    state.list.lists.map((list) => ({
      id: list.id,
      title: list.name,
      active: list.active,
      listId: list.listId,
      amount: list.amount,
      stripePlanId: list.stripePlanId,
      billingCycle: list.billingCycle,
      slotsAvailable: list.slotsAvailable,
      setLoader: setListLoader,
    }))
  );
  const [loading, setLoading] = useState(null);
  const [showBuyConfirmation, setShowBuyConfirmation] = useState(false);
  const [plan, setPlan] = useState(null);

  const newCards = [
    {
      planType: 'monthly',
      planName: 'Brozen',
      planAmount: 50 ,
      planId: process.env.REACT_APP_REVISED_MONTLY_PRICE_ID,
      features: [
        "6 winning products a day",
        "Minimum $2 profit per unit",
        "Inventory Tracking Ssytem",
        "Build in profit & Sales calculator",
    ],    
    }, {
      planType: 'Annual',
      planName: 'Brozen',
      planAmount: 480,
      planId: process.env.REACT_APP_REVISED_YEARLY_PRICE_ID,
      features: [
        "6 winning products a day",
        "Minimum $2 profit per unit",
        "Inventory Tracking Ssytem",
        "Build in profit & Sales calculator",
        "Community Access",
    ],
          
    }
  ]

  const buyPlan = async (plan) => {
    console.log(plan, "plan.id");
    try {
      const { url } = await upgradeSubscription(
        user.userId,
        user.userEmail,
        plan.id,
        plan.planId,
        user.stripeCustomerId
      );

      toast.success("Go to checkout!");
      const newWindow = window.open(url, "_self", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
      setShowBuyConfirmation(false);
      setPlan(null);
      // dispatch(createCheckoutSession(plan));
    } catch (err) {
      console.log("Something went wrong in buying plan");
      setShowBuyConfirmation(false);
      setPlan(null);
      console.log(err);
    } finally {
      setLoading(null);
    }
  };

  useEffect(() => {
    async function getProdList() {
      if (data.length) {
        const activeListIds = data
          .filter((list) => list.active === true) 
          .map((list) => list.listId);
        if (activeListIds.length === 0) setLimitModal(true);
        return;
      }

      if (user && data.length === 0) {
        setListLoader(true);
        try {
          await dispatch(fetchListsAndProducts(user.userId));
        } catch (error) {
          console.error(error);
        } finally {
          setListLoader(false);
        }
      }
    }
    getProdList();

    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });


  }, [user, data.length]);


  const refreshProducts = async () => {
    try {
      setRefreshLoading(true);
      const status = await refreshProductsJson();
      // console.log(status);
      if (status === 200) {
        // console.log("sdsd");
        await dispatch(fetchListsAndProducts(user.userId));
        toast.success("Products refreshed successfully");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshLoading(false);
    }
  };

  const onUpgrade = (plan) => {
    setPlan(plan);
    setShowBuyConfirmation(true);
  }

  console.log(plan, "plan");

  return (
    <>
      {limitModal && (
        <Modal>
          <p className="font-Inter text-center text-md font-semibold text-2xl text-[#101828] ">Purchase List</p>
          <p className="font-Inter text-center text-sm text-[#475467] mt-2 w-1/2 mx-auto">
            Please purchase atleast one list to access the features
          </p>

          <button
            onClick={() => {
              setLimitModal(false);
            }}
            type="submit"
            className="font-semibold w-2/3 mx-auto font-Inter text-sm mt-8 mb-2 block  rounded-lg transition duration-200 ease-in bg-[#2970FF] hover:bg-[#2970FF] py-2.5 text-white"
          >
            Purchase Now
          </button>
        </Modal>
      )}
      <div className="py-4 w-full overflow-y-auto min-h-screen relative fade-in">
        <div className="flex-1 px-5 sm:px-5 w-full flex items-center justify-center sm:items-stretch sm:justify-start">
          <div className="flex items-baseline gap-x-2">
            <p className="text-2xl font-Inter font-semibold text-[#101828] ease-in duration-300 fade-in fade-in">
              Your Lists
            </p>
            <span className="border-[1.9px] h-[25px] rounded-lg -bottom-4 hidden border-gray-300"></span>
            <p className="text-sm text-secondarySupport text-Inter">View all lists here</p>
          </div>
        </div>

        <hr className="bg-gray-200 h-[1.15px] mt-3" />

        <div className="mt-4 px-5">
          {/* //connection card */}
          <div className="flex gap-x-5  h-fit">
            {listLoader ? (
              <Row gutter={16} className="flex gap-x-5">
                {Array(4)
                  .fill()
                  .map((_, i) => (
                    <Col span={4} key={i} style={{ minWidth: "10rem", height: "10rem" }}>
                      <Skeleton.Node active style={{ width: "10rem", height: "10rem" }}>
                        <BarsOutlined
                          style={{
                            fontSize: 60,
                            color: "#bfbfbf",
                          }}
                        />
                      </Skeleton.Node>
                    </Col>
                  ))}
              </Row>
            ) : (
              // <Row gutter={16}  className="flex gap-x-5" style={{ width: "100%" }}>
              //   {data.map((data, index) => (
              //     <Col span={3} key={index} >
              //       <StatusCard key={index} {...data} />
              //     </Col>
              //   ))}
              // </Row>
                newCards.map((dat, index) => (
                  <div className="" key={index} >
                    <StatusCard key={index} {...dat} currentPlanId={user?.priceId} onUpgrade={onUpgrade} />
                  </div>
                ))
            )}
          </div>

          {/* table */}
          <div className="mt-8">
            <div className="flex item-center justify-between">
              <div className="">
                <p className="text-xl fade-in text-gray-700 font-bold text-Inter">Your Products</p>
                <p className="text-sm fade-in text-secondarySupport text-Inter">View all products here</p>
              </div>
              {/* <Tooltip title="Refresh Products">
                <Button
                  onClick={() => refreshProducts()}
                  shape="circle"
                  icon={<SyncOutlined spin={refreshLoading} />}
                />
              </Tooltip> */}
              {/* <Button loading={refreshLoading} >
                Refresh Products
              </Button> */}
            </div>
          </div>

          <div className="border-[1px] border-gray-200 rounded-lg shadow-card w-full px-5 py-3 mt-4">
            <Table />
          </div>
        </div>

        {
        showBuyConfirmation && (
          <ConfirmationModal
            heading="Are you sure you want to buy this plan?"
            message="You will be charged immediately and your plan will be activated."
            onCancel={() => { setShowBuyConfirmation(false); setPlan(null) }}
            onConfirm={() => buyPlan(plan)}
            loading={loading}
            btnText="Confirm"
          />
        )
      }
      </div>
    </>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
